import React from 'react'
import { proximaFont } from 'ui'
import { CFooter } from '../components/organisms/c-footer'
import { CHeader } from '../components/organisms/c-header'
import { AssetInterface } from '../graphql/global-fragments'

type Props = {
  children: React.ReactNode
  logoImage?: {
    clearLogo: Array<AssetInterface>
    darkLogo: Array<AssetInterface>
  }
  isStella?: boolean
  footerSocials?: { socialType: string; socialLink: string }[] | undefined
}

const DefaultLayout = ({ children, logoImage, footerSocials, isStella }: Props) => {
  return (
    <main className={proximaFont.variable}>
      <CHeader logoImage={logoImage} isStella={isStella} />
      {children}
      <CFooter footerSocials={footerSocials} />
    </main>
  )
}

export default DefaultLayout
